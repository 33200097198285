import { t } from 'ttag';

export const ErrorMessages = () => ({
    GENERAL_ERROR: t`GENERAL_ERROR`,
    NETWORK_ERROR: t`NETWORK_ERROR`,
    USER_WAS_NOT_VERIFIED: t`USER_WAS_NOT_VERIFIED`,
    INCORRECT_CREDENTIALS: t`INCORRECT_CREDENTIALS`,
    EMAIL_TAKEN: t`EMAIL_TAKEN`,
    EMAIL_INVALID: t`EMAIL_INVALID`,
    USER_NOT_FOUND: t`USER_NOT_FOUND`,
    ROUTE_NOT_FOUND: t`ROUTE_NOT_FOUND`,
    CAR_NOT_FOUND: t`CAR_NOT_FOUND`,
    CAR_ALREADY_EXISTS: t`CAR_ALREADY_EXISTS`,
    CAR_REQUEST_LIMIT_REACHED: t`CAR_REQUEST_LIMIT_REACHED`,
    CAR_REQUESTS_LIMIT_REACHED: t`CAR_REQUESTS_LIMIT_REACHED`,
    CAR_AMOUNT_LIMIT_REACHED: t`CAR_AMOUNT_LIMIT_REACHED`,
    USER_CREATE_ALREADY_EXIST: t`USER_CREATE_ALREADY_EXIST`,
    USER_ACTIVATE_NOT_FOUND: t`USER_ACTIVATE_NOT_FOUND`,
    USER_ACTIVATE_ALREADY_ACTIVE: t`USER_ACTIVATE_ALREADY_ACTIVE`,
    INVALID_EXTENSION: t`INVALID_EXTENSION`,
    FLEET_SHARED_ALREADY: t`FLEET_SHARED_ALREADY`,
    TRAFICOM_API_DISABLED: t`TRAFICOM_API_DISABLED`,
    // If you are here because you are getting this error other place then user, please have backend fix the error code
    UNIQUE: t`USER_ALREADY_EXISTS`,
});

export const SuccessMessages = () => ({
    GENERAL_SUCCESS: t`GENERAL_SUCCESS`,
    PROFILE_UPDATED: t`PROFILE_UPDATED`,
    ADDRESS_CREATED: t`ADDRESS_CREATED`,
    ADDRESS_UPDATED: t`ADDRESS_UPDATED`,
    ADDRESS_DELETED: t`ADDRESS_DELETED`,
});

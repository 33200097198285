import { UPLOAD_CSV } from 'components/Fleets/common/AddVehiclesModal/actions.js';
import { CHANGE_LOCALE } from 'components/layouts/actions.js';
import {
    sortVehicles,
    applyFilters,
    updateColumnSorts,
    toggleColumnField,
    COLUMNS,
    formatCarObject,
} from '../utils';
import {
    REQUEST,
    FAILURE,
    SET_COLUMN,
    GET_VEHICLES,
    ADD_VEHICLES,
    COPY_VEHICLES,
    MOVE_VEHICLES,
    DELETE_VEHICLES,
    CLEAR_FLEET_CARS,
    SET_VEHICLE_CLASS,
    SET_VEHICLE_NEDC,
    SET_VEHICLE_WLTP,
    SET_VEHICLES_SORT,
    SET_VEHICLES_SEARCH,
    SET_VEHICLES_COLUMNS,
    TOGGLE_VEHICLE_COLUMN,
    RENAME_VEHICLE,
    GET_VEHICLE_FILE,
    SET_VEHICLE_FUEL,
    SET_VEHICLE_PLATE,
    SET_VEHICLE_INSPECTION_START,
    SET_VEHICLE_INSPECTION_END,
    SET_VEHICLE_INSPECTION_LAST,
    SET_VEHICLE_CPVS,
    GET_CPVS,
} from '../actions.js';

const parseColumn = col => ({ ...COLUMNS()[col], order: 1 });

export const initialColumns = [
    'plate',
    'name',
    'clean_acceptable_level',
    'nedc_emissions',
    'wltp_emissions',
    'euro_emission_standard',
    'fuel_type',
    'make',
    'model',
];

export const getColumns = () => {
    const storageColumns = localStorage.getItem('vehicleColumns');
    const columns = storageColumns ? storageColumns.split(',') : initialColumns;
    !storageColumns && localStorage.setItem('vehicleColumns', columns);

    return columns.map(column => parseColumn(column));
};

const initialState = {
    listVehicles: [],
    allVehicles: [],
    loading: false,
    error: null,
    sort: 'plate',
    searchText: '',
    columns: [],
    platesCSV: [],
    errorCSV: null,
    cpvs: [],
};

function vehicles(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_VEHICLES: {
            const { sort, searchText, columns } = state;
            const vehicles = action.response.map(formatCarObject);

            return {
                ...state,
                loading: false,
                allVehicles: vehicles,
                listVehicles: applyFilters(vehicles, searchText, sort, columns, 'vehicle_class'),
            };
        }
        case ADD_VEHICLES: {
            const { sort, searchText, columns, allVehicles } = state;
            const { response } = action;

            const newAllVehicles = [
                ...allVehicles,
                ...response.map(formatCarObject),
            ];

            return {
                ...state,
                allVehicles: newAllVehicles,
                listVehicles: applyFilters(newAllVehicles, searchText, sort, columns, 'vehicle_class'),
            };
        }
        case CLEAR_FLEET_CARS: {
            return {
                ...state,
                loading: false,
                listVehicles: [],
            };
        }
        case FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case SET_VEHICLES_SORT: {
            const { sort, groupBy } = action;
            const { newColumns, columnNewOrder } = updateColumnSorts(state.columns, state.sort, sort);

            return {
                ...state,
                listVehicles: sortVehicles(state.listVehicles, sort, columnNewOrder, groupBy),
                sort,
                columns: newColumns,
            };
        }
        case SET_VEHICLES_SEARCH: {
            const { searchText } = action;

            return {
                ...state,
                listVehicles: applyFilters(state.allVehicles, searchText, state.sort, state.columns, 'vehicle_class'),
                searchText,
            };
        }
        case SET_COLUMN: {
            const { index, field } = action;
            const { allVehicles, columns, sort, searchText } = state;
            const newSort = columns[index].field === sort ? field : sort;
            const newColumns = Object.assign(
                [...columns],
                {
                    [index]: {
                        field,
                        order: columns[index].order,
                    },
                },
            );

            return {
                ...state,
                columns: newColumns,
                sort: newSort,
                listVehicles: applyFilters(allVehicles, searchText, newSort, newColumns, 'vehicle_class'),
            };
        }
        case DELETE_VEHICLES: {
            const ids = action.arrCarIDs.map(id => parseInt(id, 10));
            const { allVehicles, listVehicles } = state;

            return {
                ...state,
                allVehicles: allVehicles.filter(vehicle => !ids.includes(vehicle.id)),
                listVehicles: listVehicles.filter(vehicle => !ids.includes(vehicle.id)),
            };
        }
        case MOVE_VEHICLES: {
            // TODO: Backend hasn't implemented moving multiple vehicles
            return {
                ...state,
            };
        }
        case COPY_VEHICLES: {
            return state;
        }
        case CHANGE_LOCALE: {
            // TODO: set current columns in a way it runs the translations
            return {
                ...state,
                columns: getColumns(),
            };
        }
        case SET_VEHICLES_COLUMNS: {
            return {
                ...state,
                columns: action.columns,
            };
        }
        case TOGGLE_VEHICLE_COLUMN: {
            const { field } = action;
            const { allVehicles, columns, sort, searchText } = state;
            const { newSort, newColumns } = toggleColumnField(columns, sort, field);
            localStorage.setItem('vehicleColumns', newColumns.map(column => column.field));

            return {
                ...state,
                columns: newColumns,
                sort: newSort,
                listVehicles: applyFilters(allVehicles, searchText, newSort, newColumns, 'vehicle_class'),
            };
        }
        case SET_VEHICLE_CLASS:
        case SET_VEHICLE_WLTP:
        case SET_VEHICLE_NEDC:
        case SET_VEHICLE_FUEL:
        case SET_VEHICLE_PLATE:
        case SET_VEHICLE_INSPECTION_START:
        case SET_VEHICLE_INSPECTION_END:
        case SET_VEHICLE_INSPECTION_LAST:
        case SET_VEHICLE_CPVS:
        case RENAME_VEHICLE: {
            const { id, response } = action;

            const updateCarInVehicles = v => (v.id === id ? { ...v, ...formatCarObject(response), id } : v);

            const listVehicles = state.listVehicles.map(updateCarInVehicles);
            const allVehicles = state.allVehicles.map(updateCarInVehicles);

            return {
                ...state,
                listVehicles,
                allVehicles,
            };
        }
        case UPLOAD_CSV: {
            // const { response } = action;
            // let errorCSV = null;
            // Nothing happens with CARS_CSV_UPLOAD when success
            return {
                ...state,
                // platesCSV: response.map(({ row }) => row.plate),
                // errorCSV,
            };
        }
        case GET_VEHICLE_FILE: {
            return {
                ...state,
                file: action.response,
            };
        }
        case GET_CPVS: {
            return {
                ...state,
                cpvs: action.response.cpvs,
            };
        }
        default: { return state; }
    }
}

export default vehicles;
